import { format } from "date-fns";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"; // For optimized Gatsby images
import { BgImage } from "gbimage-bridge";
import gsap from "gsap";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";

const BlogHeader = ({ blogFields }) => {
	const bgImage = getImage(
		blogFields.blogFields.featuredImage?.localFile?.childImageSharp
			?.gatsbyImageData
	); // Gatsby image processing
	const formattedDate = format(new Date(blogFields.date), "dd MMM yyyy");

	const handleMouseEnter = (e, colour) => {
		gsap.to(e.target, { color: colour, duration: 0.1 });
	};

	const handleMouseLeave = (e) => {
		gsap.to(e.target, { color: "#fff", duration: 0.1 });
	};

	return (
		<BgImage image={bgImage} className=" position-relative py-7 py-lg-9">
			<div
				className="h-100 w-100 position-absolute start-0 top-0"
				style={overlayStyles}
			></div>
			<Container style={{ zIndex: 2 }} className="h-100 position-relative">
				<Row className="justify-content-center align-items-end  align-items-md-center h-100 text-center">
					<Col className="text-white" lg={8}>
						<h1 className="display-5 mb-5">{blogFields.title}</h1>
						<div className="text-center">
							<GatsbyImage
								className="mb-4"
								image={
									blogFields.blogFields.authorImage?.localFile?.childImageSharp
										?.gatsbyImageData
								}
								alt={blogFields.blogFields.authorImage?.altText}
								style={{
									width: "50px",
									height: "50px",
									borderRadius: "50%",
								}}
							/>

							<div>
								<p
									className="outfit-bold pb-0 mb-0"
									style={{ color: "white", fontSize: "90%" }}
								>
									{blogFields.blogFields.authorName}
								</p>
								<p
									style={{ fontSize: "90%" }}
									className=" mb-0 text-white justify-content-center d-flex align-items-center"
								>
									{formattedDate}{" "}
									<span
										className="mx-2"
										style={{
											display: "inline-block",
											width: "5px",
											height: "5px",
											borderRadius: "50%",
											backgroundColor: "white",
											margin: "0 5px",
										}}
									></span>
									{blogFields.blogFields.numberOfMinRead} min read
								</p>
							</div>
						</div>
						<div className="d-flex align-items-center text-center justify-content-center mt-5 position-relative">
							<div
								className="position-absolute pe-7"
								style={{ color: "#73b43e", zIndex: -1 }}
							>
								<MdKeyboardArrowLeft className="fs-5 me-2" />
							</div>
							<Link
								onMouseEnter={(e) => handleMouseEnter(e, "#73b43e")}
								style={{ color: "white" }}
								onMouseLeave={handleMouseLeave}
								className="outfit-regular d-inline-block  mb-0 pb-0"
								to={`/content-hub`}
							>
								View All
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

// Inline styles

const overlayStyles = {
	background:
		"linear-gradient(180deg, rgba(0, 18, 33, 0.00) 6.66%, #001221 95.38%)",
};

export default BlogHeader;
