import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebookF, FaLink, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SafeHtmlParser } from "./SafeHtmlParser";

const BlogContentSection = ({ wpPage, site }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;
	const postUrl = `${siteUrl}/${wpPage.slug}`;
	const postTitle = encodeURIComponent(wpPage.title);
	const postSummary = encodeURIComponent(wpPage.blogFields?.description || "");

	// Share links
	const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
		postUrl
	)}&title=${postTitle}&summary=${postSummary}`;

	const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
		postUrl
	)}`;

	const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
		postUrl
	)}&text=${postTitle}`;

	// Get the first 4 categories
	const categories = wpPage.categories?.nodes?.slice(0, 4) || [];

	return (
		<section className="case-study-content-section py-5">
			<Container>
				<Row className=" justify-content-center">
					{/* Left Column: Main Content */}
					<Col className="blog-inner-content" lg={8}>
						<SafeHtmlParser htmlContent={wpPage.content} />
					</Col>

					{/* Right Column: "Spread the word" & Categories */}
					<Col lg={8}>
						<div
							style={{
								borderBottom: `1px solid ${wpPage.blogFields.colour}`,
							}}
							className="mb-lg-5 pb-lg-5 mb-4 pb-3"
						>
							<Row className="align-items-end ">
								<Col xs={12} md={4} xl={3}>
									<h5 className="mb-4 f-6 text-primary">Spread the word</h5>
									<div className="d-flex gap-2">
										<a
											href="#"
											onClick={() =>
												navigator.clipboard.writeText(window.location.href)
											}
											aria-label="Copy link"
										>
											<div
												className="position-relative d-flex align-items-center justify-content-center"
												style={{
													borderRadius: "50%",
													width: "30px",
													height: "30px",
													overflow: "hidden",
												}}
											>
												<div
													style={{
														background: wpPage.blogFields.colour,
														opacity: 0.1,
													}}
													className="w-100 h-100 position-absolute start-0 top-0"
												></div>
												<FaLink
													className="position-relative fs-5"
													style={{ color: wpPage.blogFields.colour, zIndex: 2 }}
												/>
											</div>
										</a>
										<a
											href={linkedinShareUrl}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="Share on LinkedIn"
										>
											<div
												className="position-relative d-flex align-items-center justify-content-center"
												style={{
													borderRadius: "50%",
													width: "30px",
													height: "30px",
													overflow: "hidden",
												}}
											>
												<div
													style={{
														background: wpPage.blogFields.colour,
														opacity: 0.1,
													}}
													className="w-100 h-100 position-absolute start-0 top-0"
												></div>
												<FaLinkedin
													className="position-relative fs-5"
													style={{ color: wpPage.blogFields.colour, zIndex: 2 }}
												/>
											</div>
										</a>
										<a
											href={twitterShareUrl}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="Share on X (Twitter)"
										>
											<div
												className="position-relative d-flex align-items-center justify-content-center"
												style={{
													borderRadius: "50%",
													width: "30px",
													height: "30px",
													overflow: "hidden",
												}}
											>
												<div
													style={{
														background: wpPage.blogFields.colour,
														opacity: 0.1,
													}}
													className="w-100 h-100 position-absolute start-0 top-0"
												></div>
												<FaXTwitter
													className="position-relative fs-5"
													style={{ color: wpPage.blogFields.colour, zIndex: 2 }}
												/>
											</div>
										</a>
										<a
											href={facebookShareUrl}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="Share on Facebook"
										>
											<div
												className="position-relative d-flex align-items-center justify-content-center"
												style={{
													borderRadius: "50%",
													width: "30px",
													height: "30px",
													overflow: "hidden",
												}}
											>
												<div
													style={{
														background: wpPage.blogFields.colour,
														opacity: 0.1,
													}}
													className="w-100 h-100 position-absolute start-0 top-0"
												></div>
												<FaFacebookF
													className="position-relative fs-5"
													style={{ color: wpPage.blogFields.colour, zIndex: 2 }}
												/>
											</div>
										</a>
									</div>
								</Col>

								{/* Categories Section */}
								<Col xs={12} md={8} xl={9} className="text-md-end mt-4 mt-md-0">
									<div className="d-flex flex-wrap gap-2 justify-content-md-end">
										{categories.map((category, i) => (
											<div key={i} className="p-1 px-2 position-relative">
												<div
													style={{
														background: wpPage.blogFields.colour,
														opacity: 0.2,
													}}
													className="position-absolute w-100 h-100 start-0 top-0"
												></div>
												<p
													style={{ zIndex: 2 }}
													className="outfit-medium position-relative py-0 my-0"
												>
													{category.name}
												</p>
											</div>
										))}
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				{/* Author Section */}
				<Row className=" justify-content-center">
					{/* Left Column: Main Content */}
					<Col lg={8}>
						<div className="d-flex align-items-center">
							{wpPage.blogFields.authorImage?.localFile?.childImageSharp
								?.gatsbyImageData && (
								<GatsbyImage
									className=""
									image={
										wpPage.blogFields.authorImage.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={wpPage.blogFields.authorImage?.altText || "Author Image"}
									style={{
										width: "60px",
										height: "60px",
										borderRadius: "50%",
									}}
								/>
							)}
							<div className="d-block ms-3">
								<p
									className="outfit-bold pb-0 mb-0"
									style={{ fontSize: "90%" }}
								>
									{wpPage.blogFields.authorName}
								</p>
								<p
									className="mb-0 pb-0"
									style={{ color: wpPage.blogFields.colour }}
								>
									{wpPage.blogFields.authorRole}
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default BlogContentSection;
